import { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/base/Button";
import Layout from "../components/Layout";
import { api, apiAnom, notifError, getAnomToken } from "../utils/api";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { authState } from "../atoms/authState";
import Cookies from "js-cookie";
import PageLoading from "./PageLoading";
import { InputPassword } from "../components/InputPassword";
import Footer from "../components/Footer";
import Headerv2 from "../components/Header_v2";
import { signInUpState } from "../atoms/signInUpState";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import { userUpdatedState } from "../atoms/userUpdatedState";
import { userUpdatePromptState } from "../atoms/userUpdatePromptState";
import SigninupSlide from "../components/SigninupSlide";
import Slider from "react-slick";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonV2 from "../components/ButtonV2";
import SignInCarouselSlides from "../components/SignInCarouselSlides";
import LayoutV2 from "../components/Layout_V2";
import FormField from "../components/FormField";
import charCardInfo from "./CharCardInfo.json";

export default function LoginV2({ login, register }) {
  const charCardsRef = useRef();
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(true);
  const setAuth = useSetRecoilState(authState);
  const userUpdate = useRecoilValue(userUpdatedState);
  const setUserUpdatedState = useSetRecoilState(userUpdatedState);
  const auth = useRecoilValue(authState);
  const sign = useRecoilValue(signInUpState);
  const setSign = useSetRecoilState(signInUpState);
  const navigate = useNavigate();
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const setUpdatePrompt = useSetRecoilState(userUpdatePromptState);
  const [insertEmail, setInsertEmail] = useState(false);

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener("resize", handleWindowResize);

    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  useEffect(() => {
    getAnomToken().finally(() => {
      setPageLoading(false);
    });
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    const { elements } = e.target;
    setLoading(true);

    const storage = localStorage.getItem("TMB_ANSWERS");
    if (storage != null) {
      const answers = JSON.parse(storage);
      const result = await apiAnom.post(
        `/tmb/submit-test-result?email=${
          elements[0].value
        }&ptmq_id=${Cookies.get("ptmqID")}`,
        answers
      );
      if (result?.data?.code === 200) {
        Cookies.remove("ptmqID");
        localStorage.removeItem("TMB_ANSWERS");
      }
    }

    apiAnom
      .post("/auth/student", {
        email: elements[0].value,
        password: elements[1].value,
      })
      .then((res1) => {
        if (res1.data.code === 202) {
          navigate(`/b2b-email-check/user?username=${elements[0].value}`);
        } else {
          const tmb_result_id = res1.data.data.tmb_result_id;
          Cookies.set("user.token", res1.data.data.request_token, {
            sameSite: "None",
            secure: true,
            expires: 1,
          });
          if (res1.data.data.is_student_inject) {
            navigate("/link-email");
            return;
          }

          api
            .get("/me", {
              headers: {
                Authorization: "Bearer " + res1.data.data.request_token,
              },
            })
            .then((res) => {
              let isUpdated;
              if (res.data.data.email === "") {
                setInsertEmail(true);
                return;
              }
              api
                .get("/me/last-edu-lvl-update")
                .then((res) => {
                  setUserUpdatedState({
                    currentEduYearStart: res.data.data.current_edu_year_start,
                    currentEduYearEnd: res.data.data.current_edu_year_end,
                    isUpdated:
                      res.data.data.student_log.created_at.split("-")[0] ===
                      "0001"
                        ? false
                        : true,
                  });
                  isUpdated =
                    res.data.data.student_log.created_at.split("-")[0] ===
                    "0001"
                      ? false
                      : true;
                })
                .catch(notifError);

              setAuth({ ...res.data.data, isAuth: true });
              const params = new URLSearchParams(window.location.search);
              let path = "/profile";
              if (params.get("redirect")) {
                path = params.get("redirect");
                if (path.split("/")[1] === "explore-kelas") {
                  Cookies.set("confirmClass", true, {
                    sameSite: "None",
                    secure: true,
                  });
                }
                navigate(path);
              } else {
                if (res.data.data.phone === "" || !res.data.data.verified_at) {
                  path = "/profile-edit";
                }
                if (res.data.data.verified_at) {
                  if (res.data.data.verified_at.split("-")[0] === "0001") {
                    path = "/profile-edit";
                  }
                }
                if (tmb_result_id > 0) {
                  navigate(`/tes-minat-result${tmb_result_id}`);
                }
                if (res.data.data.username === "") {
                  navigate(`/profile-edit`);
                } else {
                  setTimeout(() => {
                    navigate(path);
                    setUpdatePrompt({ isOpen: !isUpdated });
                  }, 300);
                }
              }
            })
            .catch(notifError);
        }
      })
      .catch(notifError)
      .finally(() => {
        setLoading(false);
      });
  };

  const handleKeyDown = (e) => {
    const { id } = e.target;

    if (e.key === "Enter") {
      e.preventDefault();

      const form = e.target.form;
      const usernameField = document.getElementById("username");
      const passwordField = document.getElementById("password");

      // Reset custom validity messages
      usernameField.setCustomValidity("");
      passwordField.setCustomValidity("");

      // Check for empty fields and set custom messages
      if (!usernameField.value) {
        usernameField.setCustomValidity("Please fill out this field.");
      }
      if (!passwordField.value) {
        passwordField.setCustomValidity("Please fill out this field.");
      }

      // If there are any validity issues, show the native validation messages
      if (!form.checkValidity()) {
        usernameField.reportValidity();
        passwordField.reportValidity();
        return; // Stop submission
      }

      // Focus on password field or submit the form
      if (id === "username") {
        passwordField.focus();
      } else if (id === "password") {
        form.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }
    }
  };

  const handleInput = (e) => {
    // Clear custom validity when the user starts typing
    e.target.setCustomValidity("");
  };

  // DO NOT DELETE!!! FOR FUTURE DEVELOPMENT
  // const googleSignin = useGoogleLogin({
  //   // onSuccess: codeResponse => console.log(codeResponse),
  //   flow: "auth-code",
  //   ux_mode: "redirect",
  //   redirect_uri: process.env.REACT_APP_GAUTH_REDIRECT_URI,
  //   // redirect_uri: "http://localhost:3000/auth/google/callback/",
  // });

  const charCardsSettings = {
    arrows: false,
    className: "center",
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    speed: 500,
    autoplaySpeed: 3000,
  };

  const charCards = charCardInfo.map((item, index) => (
    <SignInCarouselSlides
      key={index}
      backgroundColor={item.backgroundColor}
      iconUrl={
        window.innerWidth < 1024 ? item.iconUrlMobile : item.iconUrlDesktop
      }
    />
  ));

  if (pageLoading) {
    return <PageLoading />;
  }

  return (
    <LayoutV2 padding="0">
      <div
        className="w-full sm:flex sm:justify-end relative"
        style={{
          padding:
            window.innerWidth < 1024 ? "27px 35.2px 0px 25px" : "32px 71px",
          minHeight: window.innerWidth < 1024 ? 661 : 514,
          height: window.innerHeight - (window.innerWidth < 1024 ? 210 : 145),
        }}
      >
        <div className="w-screen h-full absolute top-0 left-0">
          <Slider ref={charCardsRef} {...charCardsSettings}>
            {charCards.map((item) => item)}
          </Slider>
        </div>
        <div
          className="w-full h-full flex flex-col justify-start items-stretch relative z-10"
          style={{
            gap: "15px",
            width: window.innerWidth >= 1024 && "485px",
          }}
        >
          <div
            className="w-full flex flex-col bg-white p-6 gap-5 sm:gap-6"
            style={{
              borderRadius: "12.33px",
            }}
          >
            <p
              className="text-left font-bold text-2xl text-black"
              style={{ lineHeight: "30.24px" }}
            >
              Masuk
            </p>
            <form
              onSubmit={onSubmit}
              onKeyDown={handleKeyDown}
              className="w-full flex flex-col gap-5 sm:gap-6"
            >
              <FormField
                id="username"
                title="Email/Username"
                placeholder="Masukkan alamat email atau username"
                isRequired={true}
                onInput={handleInput}
              />
              <FormField
                id="password"
                title="Password"
                placeholder="Masukkan password"
                isPassword={true}
                isRequired={true}
                onInput={handleInput}
              />
              <div className="w-full flex flex-col justify-start items-stretch gap-3 sm:gap-6">
                <button
                  type="button"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                  className="text-right font-normal"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16.38px",
                    color: "rgba(23, 23, 23, 0.6)",
                  }}
                >
                  Lupa password?
                </button>
                <ButtonV2 type="submit" title="Masuk" />
                <p
                  className="text-left font-normal"
                  style={{
                    fontSize: "13px",
                    lineHeight: "16.38px",
                    color: "#171717",
                  }}
                >
                  Belum punya akun?{" "}
                  <a
                    href="/signup"
                    rel="noreferrer"
                    style={{ color: "#662E9B" }}
                  >
                    Daftar{window.innerWidth >= 1024 && " sekarang"}
                  </a>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </LayoutV2>
  );
}
