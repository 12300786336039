export default function SignInCarouselSlides({ backgroundColor, iconUrl }) {
  return (
    <div
      className="w-screen h-full relative"
      style={{
        backgroundColor: backgroundColor,
        minHeight: window.innerWidth < 1024 ? 661 : 514,
        height: window.innerHeight - (window.innerWidth < 1024 ? 210 : 145),
      }}
    >
      <img src={iconUrl} className="absolute bottom-0 left-0" />
    </div>
  );
}
