import {
  Routes,
  Route,
  useLocation,
  Outlet,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { useEffect, useState } from "react";
import Cookies from "js-cookie";
import AOS from "aos";
import HomeV2 from "./pages/Home_v2";
import Login from "./pages/Login";
import Register from "./pages/RegisterV2";
import RegisterV3 from "./pages/RegisterV3";
import ClassRoom from "./pages/Classroom";
import Profile from "./pages/Profile";
import ConfirmationRegistration from "./pages/confirmation/Registration";
import Feedback from "./pages/Feedback";
import CreatePassword from "./pages/CreatePassword";
import PageNotFound from "./pages/PageNotFound";
import Attend from "./pages/Attend";
import Logout from "./pages/Logout";
import { useRecoilValue } from "recoil";
import { authState } from "./atoms/authState";
import ClassroomDetail from "./pages/ClassroomDetail";
import Certificate from "./pages/Certificate";
import FAQ from "./pages/FAQ";
import ChangePassword from "./pages/ChangePassword";
import ForgotPassword from "./pages/ForgotPassword";
import ProfileEdit from "./pages/ProfileEdit";
import ConfirmationEmailSent from "./pages/confirmation/EmailSent";
import SuccessResetPassword from "./pages/confirmation/SuccessResetPassword";
import EksplorasiKampus from "./pages/portal_informasi/EksplorasiKampus";
import EksplorasiJurusan from "./pages/portal_informasi/EksplorasiJurusan";
import MajorDetail from "./pages/portal_informasi/details/MajorDetail";
import UniversityDetail from "./pages/portal_informasi/details/UniversityDetail";
import UnivComparation from "./pages/UnivComparation";
import TestMinatBakat from "./pages/TestMinatBakat";
import TMBResultDetail from "./pages/TMBResultDetail";
import UniversityDetailSlug from "./pages/portal_informasi/details/UniversityDetailSlug";
import ClassroomDetailSlug from "./pages/ClassroomDetailSlug";
import LoginV2 from "./pages/LoginV2";
import ProfileEditV2 from "./pages/ProfileEditV2";
import Callback from "./pages/Callback";
import AdmissionForm from "./pages/admission_form/AdmissionForm";
import EmailVerifCallback from "./pages/EmailVerifCallback";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TnC from "./pages/TnC";
import LaSalleAdmissionForm from "./pages/admission_form/LaSalleAdmission";
import SampoernaAdmissionForm from "./pages/admission_form/SampoernaAdmission";
import WidyatamaAdmissionForm from "./pages/admission_form/WidyatamaAdmission";
import ClassroomDetailSlugV2 from "./pages/ClassroomDetailSlugV2";
import ClassRoomV2 from "./pages/portal_informasi/ClassroomV2";
import ExplorasiPenerimaan from "./pages/portal_informasi/ExplorasiPenerimaan";
import ProfileV2 from "./pages/ProfileV2";
import Invoice from "./pages/Invoice";
import PaymentStatus from "./pages/PaymentStatus";
import LaSalleAdmissionFormV2 from "./pages/admission_form/LaSalleAdmissionV2";
import LaSalleAdmissionFormV4 from "./pages/admission_form/LaSalleAdmissionV4";
import QuizMinatBakatV2 from "./pages/minat_bakat/QuizMinatBakatV2";
import { getAnomToken } from "./utils/api";
import RMSignin from "./pages/rm/RMSignin";
import RMSubmission from "./pages/rm/RMSubmission";
import { rmAuthState } from "./atoms/rmAuthState";
import ExclusiveClassroom from "./pages/portal_informasi/ExclusiveClassroom";
import RMVerifCallback from "./pages/RMVerifCallback";
import FAQSummerCamp from "./pages/FAQSummerCamp";
import ExploreSummerCamp from "./pages/HomeSummerCamp";
import RMDashboard from "./pages/rm/RMDashboard";
import TMBMiniQuizPage from "./pages/minat_bakat/mini_quiz/TMBMiniQuizPage";
import TesMinatResult from "./pages/tmb_result_revamp/TesMinatResult";
import TMBResultJurusan from "./pages/tmb_result_revamp/TMBResultJurusan";
import TMBResultKarakter from "./pages/tmb_result_revamp/TMBResultKarakter";
import LinkToEmail from "./pages/LinkToEmail";
import EksplorasiKampusV3 from "./pages/portal_informasi/EksplorasiKampusV3";
import UniversityDetailSlugV3 from "./pages/portal_informasi/details/UniversityDetailSlugV3";
import EksplorasiJurusanV3 from "./pages/portal_informasi/EksplorasiJurusanV3";
import MajorDetailSlugV2 from "./pages/portal_informasi/details/MajorDetailSlugV2";
import HomeV4 from "./pages/Home_v4";
import B2BEmailCheck from "./pages/B2BEmailCheck";
import ChangePasswordV2 from "./pages/ChangePasswordV2";
import ResetAccount from "./pages/ResetAccount";

function Authenticated() {
  const auth = useRecoilValue(authState);
  if (!Cookies.get("user.token") && !auth.isAuth) {
    return (
      <Navigate
        to={`/signin?redirect=${
          window.location.pathname + window.location.search
        }`}
      />
    );
  }
  if (!auth.isAuth) {
    return <Navigate to="/signin" />;
  }
  return <Outlet />;
}

function UnAuthenticated() {
  const auth = useRecoilValue(authState);
  if (Cookies.get("user.token") || auth.isAuth) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
}

function AuthenticatedRM() {
  const rmAuth = useRecoilValue(rmAuthState);
  if (!rmAuth.isAuth) {
    return <Navigate to="/rm/signin" />;
  }
  return <Outlet />;
}

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const [tokenFetched, setTokenFetched] = useState(false);
  const userAuth = useRecoilValue(authState);

  useEffect(() => {
    if (userAuth.isAuth && window.location.pathname !== "/logout" && !/\/students\/verification\/[^/]+$/.test(window.location.pathname)) {
      if (
        userAuth.verified_at.split("-")[0] === "0001" ||
        userAuth.fname === "" ||
        userAuth.lname === "" ||
        userAuth.phone === "" ||
        userAuth.class === ""
      ) {
        navigate("/profile-edit");
      }
    }
    if (window.location.pathname === "/logout" && !userAuth.isAuth) {
      navigate("/");
    }
  }, [window.location.pathname]);

  useEffect(() => {
    if (Cookies.get("anom.token")) {
      setTokenFetched(true);
    } else {
      const fetchData = async () => {
        try {
          await getAnomToken();
          setTokenFetched(true);
        } catch (error) {
          console.error("Error fetching token:", error);
        }
      };

      fetchData();
    }
  }, []);

  useEffect(() => {
    window &&
      window.ga &&
      window.ga("send", "pageview", {
        page: window.location.pathname + window.location.search,
      });
  }, [location]);

  useEffect(() => {
    AOS.init({
      once: true,
      disable: "phone",
      duration: 700,
      easing: "ease-out-cubic",
    });
  }, []);

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]);

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {tokenFetched && (
        <Routes>
          <Route path="/" element={<HomeV4 />} />
          <Route path="/old-home" element={<HomeV2 />} />
          <Route element={<UnAuthenticated />}>
            <Route path="/signup" element={<RegisterV3 />} />
            <Route path="/create-password" element={<CreatePassword />} />
            <Route path="/change-password" element={<ChangePassword />} />
            <Route
              path="/change-password-b2b/:user"
              element={<ChangePasswordV2 />}
            />
            <Route
              path="/reset-account/:user"
              element={<ResetAccount />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signin" element={<LoginV2 />} />
            <Route path="/b2b-email-check/:user" element={<B2BEmailCheck />} />
            <Route path="/sent" element={<ConfirmationEmailSent />} />
            <Route path="/success-reset" element={<SuccessResetPassword />} />
          </Route>
          {/* MAIN MENU */}
          <Route path="/explore-jurusan" element={<EksplorasiJurusanV3 />} />
          <Route
            path="/explore-jurusan/:slug"
            element={<MajorDetailSlugV2 />}
          />
          <Route path="/test-minat" element={<TestMinatBakat />} />
          <Route path="/tes-minat" element={<QuizMinatBakatV2 />} />
          <Route
            path="/mini-quiz-ipa-ips"
            element={<TMBMiniQuizPage standalone={true} />}
          />
          <Route path="/tmb-result-detail/:id" element={<TMBResultDetail />} />
          <Route path="/explore-kampus" element={<EksplorasiKampusV3 />} />
          <Route
            path="/tes-minat-result/:id"
            element={
              <TesMinatResult
                screenWidth={screenWidth}
                screenHeight={screenHeight}
              />
            }
          />
          <Route
            path="/tmb-result-detail/jurusan/:id"
            element={
              <TMBResultJurusan
                screenWidth={screenWidth}
                screenHeight={screenHeight}
              />
            }
          />
          <Route
            path="/tmb-result-detail/karakter/:id"
            element={
              <TMBResultKarakter
                screenWidth={screenWidth}
                screenHeight={screenHeight}
              />
            }
          />
          <Route
            path="/explore-kampus/:slug"
            element={<UniversityDetailSlugV3 />}
          />
          <Route
            path="/explore-kampus/detail/:id"
            element={<UniversityDetail />}
          />
          <Route path="/explore-kelas" element={<ClassRoomV2 />} />
          <Route
            path="/explore-kelas-eksklusif"
            element={<ExclusiveClassroom />}
          />
          <Route path="/explore-summer-camp" element={<ExploreSummerCamp />} />
          <Route
            path="/explore-summer-camp/:slug"
            element={<ClassroomDetailSlugV2 />}
          />
          <Route path="/explore-kelas-old" element={<ClassRoom />} />
          <Route
            path="/explore-kelas/:slug"
            element={<ClassroomDetailSlugV2 />}
          />
          <Route
            path="/explore-kelas-v1/:slug"
            element={<ClassroomDetailSlug />}
          />
          <Route path="/feedback" element={<Feedback />} />
          <Route element={<Authenticated />}>
            <Route path="/profile" element={<ProfileV2 />} />
            <Route path="/profileV2" element={<Profile />} />
            <Route path="/profile-edit" element={<ProfileEditV2 />} />
            <Route path="/attend" element={<Attend />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/payment" element={<PaymentStatus />} />
          </Route>
          <Route path="/explore-penerimaan" element={<ExplorasiPenerimaan />} />
          <Route path="/daftar-kampus/:slug" element={<AdmissionForm />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/certificate" element={<Certificate />} />
          <Route
            path="/confirmation/registration"
            element={<ConfirmationRegistration />}
          />
          {/* <Route path="/terms-condition" element={<TermsCondition />} /> */}
          <Route path="/faq" element={<FAQ />} />
          <Route path="/faq/summer-camp" element={<FAQSummerCamp />} />
          <Route path="/bandingkan-kampus" element={<UnivComparation />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/auth/google/callback/" element={<Callback />} />
          <Route
            path="/students/verification/:token"
            element={<EmailVerifCallback />}
          />
          <Route
            path="/rm/leads/update/payment-status/:base64"
            element={<RMVerifCallback />}
          />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          <Route path="/terms-condition" element={<TnC />} />
          <Route
            path="/admission/lasalle-college-jakarta/v2"
            element={<LaSalleAdmissionFormV2 />}
          />
          <Route
            path="/admission/lasalle-college-jakarta/origin"
            element={<LaSalleAdmissionForm />}
          />
          <Route
            path="/admission/lasalle-college-jakarta"
            element={<LaSalleAdmissionFormV4 />}
          />
          <Route
            path="/admission/sampoerna-university"
            element={<SampoernaAdmissionForm />}
          />
          <Route
            path="/admission/universitas-widyatama"
            element={<WidyatamaAdmissionForm />}
          />

          <Route path="/rm/signin" element={<RMSignin />} />
          <Route path="/rm/dashboard" element={<RMDashboard />} />
          <Route element={<AuthenticatedRM />}>
            <Route path="/summer-camp-2024" element={<RMSubmission />} />
          </Route>

          <Route path="/link-email" element={<LinkToEmail />} />

          {/* ARCHIVED ROUTE - TO BE REMOVE LATER */}
          <Route path="/explore-kampus-old" element={<EksplorasiKampus />} />
          <Route
            path="/explore-kampus-v1/:slug-v2"
            element={<UniversityDetailSlug />}
          />
          <Route path="/signup-old" element={<Register />} />
          <Route path="/explore-jurusan-old" element={<EksplorasiJurusan />} />
          <Route path="/explore-jurusan/detail/:id" element={<MajorDetail />} />
          <Route path="/profile-edit-old" element={<ProfileEdit />} />
          <Route path="/classroom" element={<ClassRoom />} />
          <Route path="/classroom/:id" element={<ClassroomDetail />} />
          <Route
            path="/explore-kelas/detail/:id"
            element={<ClassroomDetail />}
          />
          {/* <Route path="/bandingkan-kampus-v2" element={<UnivComparationV2 />} /> */}
        </Routes>
      )}
    </>
  );
}

export default App;
