import Footer from "./Footer";
// import HeaderNew from "./HeaderNew";
import Headerv2 from "./Header_v2";
import SignInModal from "./SignInModal";
import SignUpModal from "./SignUpModal";
import { useEffect, useState } from "react";
import TMBSignInModal from "./TMBSignInModal";
import TMBSignUpModal from "./TMBSignUpModal";
import MobileViewMenu from "./MobileViewMenu";

export default function LayoutV2({
  children,
  padding,
  showModal,
  noHeight,
  withoutHeader,
  withoutBody,
  withoutFooter,
  bgColor,
  showMenu,
  isRM,
  customStyle,
}) {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [viewportWidth, setViewportWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight);
      setViewportWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div
      className="relative min-w-full flex flex-1 flex-col justify-start items-stretch overflow-x-hidden font-plusJakartaSans"
      style={{
        ...customStyle,
        minHeight: !isMenuOpen ? !noHeight && viewportHeight : viewportHeight,
        maxWidth: viewportWidth,
        backgroundColor: bgColor ? bgColor : "white",
      }}
    >
      {!withoutHeader && (
        <Headerv2
          isRM={isRM}
          screenWidth={viewportWidth}
          showModal={showModal}
          showMenu={() => {
            setIsMenuOpen(!isMenuOpen);
            if (showMenu) {
              showMenu(isMenuOpen);
            }
          }}
        />
      )}
      {isMenuOpen ? (
        <MobileViewMenu
          setIsMenuOpen={(e) => {
            setIsMenuOpen(e);
          }}
          isMenuOpen={isMenuOpen}
        />
      ) : (
        !withoutBody && (
          <div
            className={`w-full h-full flex flex-1 flex-col justify-start items-stretch ${
              !padding && "px-4 tablet:px-18"
            }`}
            style={{ padding: padding, minHeight: "634px" }}
          >
            {children}
          </div>
        )
      )}
      {!withoutFooter ? !isMenuOpen && <Footer /> : <div />}
      <SignInModal />
      <SignUpModal />
      <TMBSignInModal />
      <TMBSignUpModal />
    </div>
  );
}
