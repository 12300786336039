import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import useSWR from "swr";
import { authState } from "../../atoms/authState";
import Layout from "../../components/Layout";
import { api, apiAnom, notifError } from "../../utils/api";
import StepOne from "./StepOne";
import StepTwoV2 from "./StepTwoV2";
import StepThreeV2 from "./StepThreeV2";
import StepFourV2 from "./StepFourV2";
import { tmbSignInUpState } from "../../atoms/tmbSignInUpState";
import { signInUpState } from "../../atoms/signInUpState";
import TMBPretestPage from "./pretest/TMBPretestPage";
import TMBMiniQuizPage from "./mini_quiz/TMBMiniQuizPage";
import Cookies from "js-cookie";
import Headerv2 from "../../components/Header_v2";
import Footer from "../../components/Footer";
import LayoutV2 from "../../components/Layout_V2";

const DEFAULT_PROGRES = 15;
export default function QuizMinatBakatV2() {
  const auth = useRecoilValue(authState);
  const _api = auth.isAuth ? api : apiAnom;
  const { state } = useLocation();
  let navigate = useNavigate();
  const setSign = useSetRecoilState(signInUpState);
  const [activeIndex, setActiveIndex] = useState(1);
  const [activeProgress, setActiveProgress] = useState(DEFAULT_PROGRES);
  const [testIndex, setTestIndex] = useState(0);
  const [miniQuizData, setMiniQuizData] = useState();
  const [pretestData, setPretestData] = useState();
  const [ptmqData, setPTMQData] = useState({
    student_id: 0,
    pre_test_result: {
      email: "",
      education_level_id: 0,
      major_interest: "",
      major: "",
      is_interest_another_major: null,
    },
    mini_quiz_result: {
      email: "",
      answers: [],
      email: "",
    },
  });
  const [eduLevelId, setEduLevelId] = useState(0);
  const [fromMiniQuiz, setFromMiniQuiz] = useState(false);
  const [isFunQuiz, setIsFunQuiz] = useState(false);

  const { data: quiz } = useSWR("/tmb/questions", auth.isAuth ? api : apiAnom, {
    revalidateOnFocus: false,
    refreshWhenHidden: false,
  });

  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // check if user comes from Mini Quiz standalone
  useEffect(() => {
    if (state) {
      if (state.userMiniQuizData) {
        const data = state.userMiniQuizData;
        setTestIndex(0);
        setIsFunQuiz(data.is_fun_quiz);
        setPTMQData((prevState) => ({
          ...prevState,
          pre_test_result: {
            ...prevState.pre_test_result,
            is_interest_another_major: data.is_interest_another_major ?? null,
          },
          mini_quiz_result: {
            ...prevState.mini_quiz_result,
            answers: data.answers,
          },
        }));
      }
      if (state.resetTMB) {
        Cookies.remove("userPretestData");
        Cookies.remove("pretestStepIndex");
        Cookies.remove("userMQData");
        Cookies.remove("userPTMQData");
        localStorage.removeItem("TMB_ANSWERS");
        setTestIndex(0);
        window.history.replaceState({}, "");
      }
    }
  }, [state]);

  const onNextPage = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveIndex(activeIndex + 1);
    switch (activeIndex + 1) {
      case 1:
        setActiveProgress(40);
        break;
      case 2:
        setActiveProgress(50);
        localStorage.removeItem("TMB_ANSWERS");
        break;
      case 3:
        setActiveProgress(100);
        break;
      default:
        setActiveProgress(activeProgress + DEFAULT_PROGRES);
        break;
    }
  };

  const onBackPage = (e, progress) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveIndex(activeIndex - 1);
    switch (activeIndex - 1) {
      case 1:
        setActiveProgress(40);
        break;
      case 2:
        setActiveProgress(progress);
        break;
      case 3:
        setActiveProgress(100);
        break;
      default:
        setActiveProgress(activeProgress + DEFAULT_PROGRES);
        break;
    }
  };

  const submitTMB = (e) => {
    e.preventDefault();
    e.stopPropagation();
    Cookies.set("userPTMQData", JSON.stringify(ptmqData), {
      sameSite: "None",
      secure: true,
      expires: 1,
    });
    if (!auth.isAuth) {
      setSign({ trySignUp: true, trySignIn: false });
      return;
    } else {
      if (auth.verified_at.split("-")[0] === "0001") {
        navigate("/profile-edit");
        return;
      } else {
        const storage = localStorage.getItem("TMB_ANSWERS");
        const answers = JSON.parse(storage);

        const responseBody = {
          ...ptmqData,
          student_id: parseInt(auth.id),
          pre_test_result: {
            ...ptmqData.pre_test_result,
            email: auth.email,
          },
          mini_quiz_result: {
            ...ptmqData.mini_quiz_result,
            email: auth.email,
          },
        };

        _api
          .post("/ptmq/submit", responseBody)
          .then((res) => {
            Cookies.set("ptmqID", res.data.data.ptmq_id, {
              sameSite: "None",
              secure: true,
              expires: 1,
            });
            Cookies.remove("userPretestData");
            Cookies.remove("pretestStepIndex");
            Cookies.remove("userPTMQData");

            _api
              .post(
                `/tmb/submit-test-result?email=${auth.email}&ptmq_id=${res.data.data.ptmq_id}`,
                answers
              )
              .then((res) => {
                Cookies.remove("ptmqEmail");
                Cookies.remove("ptmqID");
                localStorage.removeItem("TMB_ANSWERS");
                navigate(
                  `/tmb-result-detail/jurusan/${res.data.data.tmb_result_id}`
                );
              })
              .catch(notifError);
          })
          .catch(notifError);
      }
    }
  };

  function TMBTestPage({ onBack, toPretest, eduLevelId }) {
    return (
      <div className="flex w-screen h-full relative justify-center items-center flex-1 overflow-y-hidden">
        <div
          className="flex flex-col w-screen h-full justify-between relative z-50 flex-1"
          style={{
            height: "calc(100vh - 40px - 100px)",
          }}
        >
          {activeIndex === 0 && <StepOne onNext={(e) => onNextPage(e)} />}
          {activeIndex === 1 && (
            <StepTwoV2
              screenWidth={screenWidth}
              onNext={(e) => onNextPage(e)}
              onBack={(e) => {
                if (eduLevelId > 1 || isFunQuiz) {
                  toPretest();
                } else {
                  onBack(e);
                }
              }}
              miniQuizStandalone={state && state.userMiniQuizData}
              fromMiniQuiz={fromMiniQuiz}
              isFunQuiz={isFunQuiz}
            />
          )}
          {activeIndex === 2 && (
            <StepThreeV2
              screenWidth={screenWidth}
              onNext={(e) => onNextPage(e)}
              onBack={(e) => onBackPage(e, 40)}
              quiz={quiz}
            />
          )}
          {activeIndex === 3 && (
            <StepFourV2
              screenWidth={screenWidth}
              onNext={(e) => submitTMB(e)}
              onBack={(e) => onBackPage(e, 90)}
            />
          )}
        </div>
        <div
          className="flex w-screen h-full absolute z-10"
          style={{
            background:
              "linear-gradient(0deg, rgba(132, 88, 170, 1), rgba(124, 77, 165, 1), rgba(102, 46, 150, 1), rgba(102, 46, 150, 1))",
          }}
        />
        <img
          src="/bg_wrecked_paper.svg"
          className="object-cover absolute z-20 w-full h-full"
          style={{
            mixBlendMode: "multiply",
          }}
        />
      </div>
    );
  }

  function ToPretest() {
    setTestIndex(0);
  }

  function PrevTest() {
    setTestIndex(testIndex - 1);
  }

  function NextTest() {
    setTestIndex(testIndex + 1);
  }

  function ToTMB() {
    setTestIndex(2);
  }

  const testSteps = [
    <TMBPretestPage
      screenWidth={screenWidth}
      nextTest={(e) => {
        setPTMQData((prevState) => ({
          ...prevState,
          pre_test_result: {
            ...prevState.pre_test_result,
            education_level_id: parseInt(e.education_level_id) ?? 0,
            major_interest: e.major_interest ?? "",
            major: e.major ?? "",
            is_interest_another_major: e.is_interest_another_major ?? null,
          },
        }));
        NextTest();
      }}
      toTMB={(e) => {
        if (e.is_fun_quiz) {
          setPTMQData((prevState) => ({
            ...prevState,
            pre_test_result: {
              ...prevState.pre_test_result,
              education_level_id:
                parseInt(e.pre_test_result.education_level_id) ?? 0,
              major_interest: e.pre_test_result.major_interest ?? "",
              major: e.pre_test_result.major ?? "",
              is_interest_another_major:
                prevState.pre_test_result.is_interest_another_major ??
                e.pre_test_result.is_interest_another_major ??
                null,
            },
            mini_quiz_result: {
              ...prevState.mini_quiz_result,
              answers: e.mini_quiz_result,
            },
          }));
        } else {
          setPTMQData((prevState) => ({
            ...prevState,
            pre_test_result: {
              ...prevState.pre_test_result,
              education_level_id: parseInt(e.education_level_id) ?? 0,
              major_interest: e.major_interest ?? "",
              major: e.major ?? "",
              is_interest_another_major:
                prevState.pre_test_result.is_interest_another_major ??
                e.is_interest_another_major ??
                null,
            },
          }));
        }
        ToTMB();
      }}
      checkEmail={(e) => {
        setIsFunQuiz(e);
        if (e) {
          setFromMiniQuiz(true);
        }
      }}
      isFunQuizStandalone={isFunQuiz}
      funQuizStandaloneData={state && state.userMiniQuizData}
    />,
    <TMBMiniQuizPage
      screenWidth={screenWidth}
      toPretest={ToPretest}
      toTMB={(e) => {
        setPTMQData((prevState) => ({
          ...prevState,
          pre_test_result: {
            ...prevState.pre_test_result,
            is_interest_another_major:
              e.is_interest_another_major ??
              prevState.pre_test_result.is_interest_another_major,
          },
          mini_quiz_result: {
            ...prevState.mini_quiz_result,
            answers: e.answers,
          },
        }));
        ToTMB();
      }}
    />,
    <TMBTestPage
      onBack={PrevTest}
      toPretest={ToPretest}
      eduLevelId={eduLevelId}
    />,
  ];

  useEffect(() => {
    const handleUnload = () => {
      Cookies.remove("userPretestData");
      Cookies.remove("pretestStepIndex");
      window.history.replaceState({}, "");
      setPretestData();
    };

    window.addEventListener("beforeunload", handleUnload);

    return () => {
      window.removeEventListener("beforeunload", handleUnload);
    };
  }, []);

  return <LayoutV2 padding="0">{testSteps[testIndex]}</LayoutV2>;
}
