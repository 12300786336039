import PageLoading from "./PageLoading";
import { useEffect } from "react";
import Cookies from "js-cookie";
import { googleLogout } from "@react-oauth/google";
import { authState } from "../atoms/authState";
import { signInUpState } from "../atoms/signInUpState";
import { regisUniState } from "../atoms/regisUni";
import { favUniState } from "../atoms/favUni";
import { favMajorState } from "../atoms/favMajor";
import { useResetRecoilState } from "recoil";
import { useNavigate } from "react-router-dom";
import { rmAuthState } from "../atoms/rmAuthState";

export default function Logout() {
  const navigate = useNavigate();
  const resetAuth = useResetRecoilState(authState);
  const resetSign = useResetRecoilState(signInUpState);
  const resetRegisUni = useResetRecoilState(regisUniState);
  const resetFavUniState = useResetRecoilState(favUniState);
  const resetFavMajorState = useResetRecoilState(favMajorState);
  const resetRMAuthState = useResetRecoilState(rmAuthState);
  const params = new URLSearchParams(window.location.search);

  const resetAtom = () => {
    resetAuth();
    resetSign();
    resetRegisUni();
    resetFavUniState();
    resetFavMajorState();
    resetRMAuthState();
  };

  useEffect(() => {
    Cookies.remove("userPTMQData");
    const redirectPath = `${params.get("rmlogout") ? "/rm/signin" : "/"}`;
    Object.keys(Cookies.get()).forEach(function (cookieName) {
      Cookies.remove(cookieName);
    });
    resetAtom();
    window.localStorage.clear();
    Cookies.remove("user.token");
    Cookies.remove("confirmClass");
    googleLogout();
    setTimeout(() => {
      navigate(redirectPath);
    }, 300);
    // window.location.href = "/";
  }, []);

  return <PageLoading />;
}
